.featured-content {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 400px;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff7300;;
    font-size: clamp(40px, 6vw, 120px);
    font-family: Franklinatfbq, sans-serif;
    font-weight: 400;
    line-height: 28px;
  }

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.ti-font {
  animation: slideIn 1s ease-in-out;
}
