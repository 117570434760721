.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

body {
  color: #1a1b1f;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 44px;
  font-weight: 400;
  line-height: 62px;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 400;
  line-height: 46px;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

p {
  margin-bottom: 10px;
}

a {
  color: #1a1b1f;
  text-decoration: underline;
  transition: opacity .2s;
  display: block;
}

a:hover {
  color: #32343a;
}

a:active {
  color: #43464d;
}

ul {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 40px;
  list-style-type: disc;
}

li {
  margin-bottom: 10px;
}

img {
  display: block;
}

label {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

blockquote {
  border-left: 5px solid #e2e2e2;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 15px 30px;
  font-size: 20px;
  line-height: 34px;
}

figcaption {
  opacity: 1;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  line-height: 26px;
}

.divider {
  height: 1px;
  background-color: #eee;
}

.styleguide-content-wrap {
  text-align: center;
}

.section {
  margin-left: 30px;
  margin-right: 30px;
}

.section.cc-cta {
  background-color: #f4f4f4;
  padding-left: 80px;
  padding-right: 80px;
}

.section.cc-store-home-wrap {
  background-image: linear-gradient(#000, #fff);
}

.styleguide-block {
  text-align: left;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 80px;
  display: block;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

.paragraph-tiny {
  font-size: 12px;
  line-height: 20px;
}

.paragraph-bigger {
  opacity: 1;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
}

.paragraph-bigger.cc-bigger-light {
  opacity: .6;
}

.paragraph-bigger.cc-bigger-white-light {
  opacity: .6;
  font-family: Open Sans, sans-serif;
  position: static;
}

.button {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  border-radius: 0;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  transition: background-color .4s, opacity .4s, color .4s;
}

.button:hover {
  color: #fff;
  background-color: #32343a;
}

.button:active {
  background-color: #43464d;
}

.button.cc-jumbo-button {
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-jumbo-button.cc-jumbo-white {
  color: #000;
  background-color: #fff;
}

.button.cc-jumbo-button.cc-jumbo-white:hover {
  background-color: #f1f1f1;
}

.button.cc-jumbo-button.cc-jumbo-white:active {
  background-color: #e1e1e1;
}

.button.cc-contact-us {
  z-index: 5;
  background-color: #ff7300;
  position: relative;
}

.button.cc-white-button {
  color: #202020;
  background-color: #fff;
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-white-button:hover {
  background-color: rgba(255, 255, 255, .8);
}

.button.cc-white-button:active {
  background-color: rgba(255, 255, 255, .9);
}

.label {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.label.cc-styleguide-label {
  margin-bottom: 25px;
}

.label.cc-light {
  opacity: .6;
}

.label.cc-blog-date {
  opacity: .6;
  margin-top: 20px;
}

.heading-jumbo-tiny {
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

.rich-text {
  width: 70%;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
}

.rich-text p {
  opacity: .6;
  margin-top: 15px;
  margin-bottom: 25px;
}

.rich-text figcaption {
  opacity: .6;
}

.rich-text figure {
  margin-top: 25px;
  padding-bottom: 20px;
}

.paragraph-light {
  opacity: .6;
}

.heading-jumbo {
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Franklinatfbq, sans-serif;
  font-size: 64px;
  font-weight: 400;
  line-height: 80px;
}

.heading-jumbo-small {
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
}

.styleguide-button-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.styleguide-header-wrap {
  height: 460px;
  color: #fff;
  text-align: center;
  background-color: #1a1b1f;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

.paragraph-small {
  font-size: 14px;
  line-height: 26px;
}

.logo-container {
  width: 20%;
}

.navbar-logo {
  max-width: 100%;
}

.center-nav {
  flex-grow: 1;
  text-align: center;
}

.nav-link {
  margin: 0 15px;
  color: black;
}

.right-nav {
  display: flex;
  align-items: center;
}

.login-button {
  background-color: white;
  color: black;
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  text-transform: none;
  font-family: Franklinatfbq, sans-serif;
  font-size: 24px;
  font-weight: 6;
}

.contact-button {
  background-color: #ff7300;
  color: white;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: none;
  text-transform: none;
  font-family: Franklinatfbq;
  font-size: 1.3em;
  font-weight: bolder;
  font-weight: 6;
}

.logo-link {
  z-index: 1;
}

.logo-link:hover {
  opacity: .8;
}

.logo-link:active {
  opacity: .7;
}

.menu {
  z-index: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.navigation-wrap {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
  background-color: #fff;
  width: 100%;
}

.navigation {
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
  padding: 30px 50px;
  display: flex;
}

.navigation-item {
  opacity: .6;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.dropdown-menu {
  display: none; /* Hide dropdown by default */
  position: absolute; /* Position it relative to the nav-item */
  /* Add other styles for dropdown menu (background, padding, etc.) */
}

.nav-item {
  color: black; /* Show dropdown on hover */
}

/* Show dropdown menu when hovering over nav-item */
.nav-item:hover .dropdown-menu {
  display: block; /* Show dropdown on hover */
}

/* Style for dropdown menu links */
.dropdown-menu a {
  color: black;
}

/* Hover effect on dropdown menu links */
.dropdown-menu a:hover {
  color: #ff7300;; /* Change text color on hover */
}

.dropdown-link {
  display: block; /* To make each link take its own line */
  color: black; /* Default link color */
  padding: 5px 10px; /* Add some padding for spacing */
  text-decoration: none; /* Optional: Removes the underline */
}

.dropdown-link:hover {
  color: #ff7300;; /* Change color on hover */
}

.contact-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.nav-link {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
}

.nav-link:hover {
  color: #ff7300;;
}

.navigation-item:hover {
  opacity: .9;
}

.navigation-item:active {
  opacity: .8;
}

.navigation-item.w--current {
  opacity: 1;
  color: #1a1b1f;
  font-weight: 600;
}

.navigation-item.w--current:hover {
  opacity: .8;
  color: #32343a;
}

.navigation-item.w--current:active {
  opacity: .7;
  color: #32343a;
}

.logo-image {
  display: block;
}

.navigation-items {
  flex: none;
  justify-content: center;
  align-items: center;
  display: none;
}

.footer-wrap {
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
}

.webflow-link {
  opacity: .5;
  align-items: center;
  text-decoration: none;
  transition: opacity .4s;
  display: flex;
}

.webflow-link:hover {
  opacity: 1;
}

.webflow-link:active {
  opacity: .8;
}

.webflow-logo-tiny {
  margin-top: -2px;
  margin-right: 8px;
}

.cta-text {
  width: 70%;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

.cta-wrap {
  text-align: center;
  justify-content: center;
  padding-top: 110px;
  padding-bottom: 120px;
  display: flex;
}

.intro-header {
  height: 300px;
  opacity: 1;
  color: #000;
  -webkit-text-fill-color: inherit;
  mix-blend-mode: normal;
  background-color: #fff;
  background-clip: border-box;
  border: 1px rgba(0, 0, 0, 0);
  border-radius: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: -10;
  display: flex;
}

.intro-header.cc-subpage {
  height: 480px;
  color: #fff;
  background-color: #000;
  background-image: none;
}

.intro-content {
  width: 70%;
  max-width: 1140px;
  text-align: center;
}

.intro-content.cc-homepage {
  width: 90%;
  margin-bottom: 0;
}

.motto-wrap {
  width: 80%;
  text-align: center;
  margin-bottom: 85px;
  margin-left: auto;
  margin-right: auto;
}

.about-story-wrap {
  width: 80%;
  text-align: center;
  margin: 80px auto;
}

.our-services-grid {
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". . ."
                       ". . .";
  margin-bottom: 120px;
}

.section-heading-wrap {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.service-icon {
  margin-bottom: 30px;
}

.status-message {
  color: #fff;
  text-align: center;
  background-color: #202020;
  padding: 9px 30px;
  font-size: 14px;
  line-height: 26px;
}

.status-message.cc-success-message {
  background-color: #12b878;
}

.status-message.cc-error-message {
  background-color: #db4b68;
}

.status-message.cc-no-data {
  width: 70%;
  margin: 100px auto;
  display: block;
}

.contact-form-wrap {
  border: 1px solid #eee;
  padding: 45px 50px 50px;
}

.contact-form-grid {
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template: ". ."
  / 2.5fr 1fr;
  align-items: start;
  margin-top: 100px;
  margin-bottom: 100px;
}

.details-wrap {
  margin-bottom: 30px;
}

.get-in-touch-form {
  flex-direction: column;
  display: flex;
}

.text-field {
  border: 1px solid #e4e4e4;
  border-radius: 0;
  margin-bottom: 18px;
  padding: 21px 20px;
  font-size: 14px;
  line-height: 26px;
  transition: border-color .4s;
}

.text-field:hover {
  border-color: #e3e6eb;
}

.text-field:active, .text-field:focus {
  border-color: #43464d;
}

.text-field::-ms-input-placeholder {
  color: rgba(50, 52, 58, .4);
}

.text-field::placeholder {
  color: rgba(50, 52, 58, .4);
}

.text-field.cc-contact-field {
  margin-bottom: 25px;
}

.text-field.cc-textarea {
  height: 200px;
  padding-top: 12px;
}

.contact-form {
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.contact-form-heading-wrap {
  margin-bottom: 40px;
}

.contact-heading {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 400;
}

.map {
  height: 460px;
  margin-bottom: 100px;
  line-height: 20px;
}

.contact-team-name-wrap {
  margin-bottom: 30px;
}

.our-contacts {
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  text-align: center;
  grid-template: ". . ."
  / 1fr 1fr 1fr;
  margin-bottom: 120px;
}

.contact-team-details-wrap {
  margin-top: 30px;
}

.contact-team-pic {
  height: 150px;
  background-color: #f4f4f4;
  margin-bottom: 30px;
}

.team-pic {
  width: 100%;
  height: 420px;
  background-color: #f4f4f4;
  margin-bottom: 40px;
}

.team-members {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". . ."
                       ". . .";
  margin-bottom: 60px;
}

.team-member-title-wrap {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  display: flex;
}

.team-member-name {
  opacity: 1;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
}

.projects-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template: "."
                 "."
                 "."
                 / 1fr;
  margin-bottom: 60px;
}

.project-name-wrap {
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  display: flex;
}

.project-name-link {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  text-decoration: none;
}

.project-name-link:hover {
  opacity: .8;
}

.project-name-link:active {
  opacity: .7;
}

.project-cover-link {
  margin-bottom: 40px;
}

.project-cover-link:hover {
  opacity: .8;
}

.project-cover-link:active {
  opacity: .7;
}

.project-overview-header {
  height: 620px;
  color: #fff;
  background-color: #f4f4f4;
  background-image: url('../images/portfolio-1---wide.svg');
  background-position: 50%;
  background-size: cover;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  display: flex;
}

.project-overview-header.cc-project-2-header {
  background-image: url('../images/portfolio-2---wide.svg');
  background-position: 50%;
}

.project-overview-header.cc-project-3-header {
  background-image: url('../images/portfolio-3---wide.svg');
  background-size: cover;
}

.project-details-grid {
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template: ". ."
  / 1fr 2fr;
  align-items: start;
  margin-top: 100px;
  margin-bottom: 100px;
}

.project-pics {
  margin-bottom: 120px;
}

.detail-image {
  width: 100%;
  margin-bottom: 30px;
}

.blog-detail-header-wrap {
  width: 70%;
  margin: 60px auto;
}

.detail-header-image {
  width: 100%;
  height: 620px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: cover;
  margin-bottom: 60px;
  display: block;
}

.detail-header-image.w--current {
  margin-bottom: 60px;
}

.blog-list-wrap {
  margin-bottom: 100px;
}

.blog-item {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.blog-preview-image {
  width: 100%;
  height: 620px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: cover;
  margin-bottom: 45px;
  transition: opacity .6s;
  display: block;
}

.blog-preview-image:hover {
  opacity: .8;
}

.blog-preview-image:active {
  opacity: .7;
}

.blog-summary-wrap {
  width: 70%;
  text-align: left;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.blog-heading-link {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 400;
  line-height: 46px;
  text-decoration: none;
}

.blog-heading-link:hover {
  opacity: .8;
}

.blog-heading-link:active {
  opacity: .7;
}

.contact-email-link {
  opacity: .6;
  margin-bottom: 5px;
}

.contact-email-link:hover {
  opacity: 1;
}

.contact-email-link:active {
  opacity: .8;
}

.protected-form {
  flex-direction: column;
  display: flex;
}

.protected-wrap {
  justify-content: center;
  padding-top: 90px;
  padding-bottom: 100px;
  display: flex;
}

.protected-heading {
  margin-bottom: 30px;
}

.utility-page-wrap {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

._404-wrap {
  width: 100%;
  height: 100%;
  background-color: #1a1b1f;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

._404-content-wrap {
  margin-bottom: 20px;
}

.home-content-wrap {
  margin-top: 80px;
  margin-bottom: 120px;
}

.home-section-wrap {
  margin-bottom: 30px;
}

.section-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.about-grid {
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template: ". ."
  / 1fr 2fr;
  align-items: center;
  margin-bottom: 80px;
}

.about-grid.cc-about-2 {
  grid-template-columns: 2fr 1fr;
  grid-template-areas: ". .";
}

.intro-text {
  width: 80%;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

.collection-wrap {
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.work-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.blog-heading {
  text-align: center;
  margin-bottom: 60px;
}

.blog-preview-wrap {
  width: 33.33%;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 45px;
}

.collection-list-wrapper {
  margin-bottom: 120px;
}

.business-article-heading {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  text-decoration: none;
}

.business-article-heading:hover {
  opacity: .8;
}

.business-article-heading:active {
  opacity: .7;
}

._w-lightbox-caption {
  background-color: rgba(0, 0, 0, .6);
}

.body {
  background-image: linear-gradient(25deg, #ff7300, #fff 60%);
}

.form {
  max-width: 1140px;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 0;
  display: flex;
}

.submit-button {
  background-color: #ff7300;
  margin-bottom: 0;
  font-family: Franklinatfbq, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.text-field-2 {
  margin-bottom: 0;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
}

.text-block {
  font-family: Franklinatfbq, sans-serif;
}

.text-span, .text-span-2 {
  color: #ff7300;
}

.form-block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 991px) {
  .section.cc-cta {
    padding-left: 0;
    padding-right: 0;
  }

  .styleguide-block {
    text-align: center;
  }

  .button {
    justify-content: center;
  }

  .button.cc-contact-us {
    display: none;
  }

  .heading-jumbo {
    font-size: 56px;
    line-height: 70px;
  }

  .logo-link.w--current {
    flex: 1;
  }

  .menu-icon {
    display: block;
  }

  .menu {
    margin-left: 30px;
    position: static;
  }

  .navigation {
    padding: 25px 30px;
  }

  .navigation-item {
    text-align: center;
    padding: 15px 30px;
    transition: background-color .4s, opacity .4s, color .4s;
  }

  .navigation-item:hover {
    background-color: #f7f8f9;
  }

  .navigation-item:active {
    background-color: #eef0f3;
  }

  .menu-button {
    padding: 0;
  }

  .menu-button.w--open {
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation-items {
    background-color: #fff;
  }

  .cta-text {
    width: auto;
  }

  .cta-wrap {
    width: auto;
    padding: 80px 50px 90px;
  }

  .our-services-grid {
    width: 80%;
    text-align: center;
    grid-template: ". ."
                   ". ."
                   ". ."
                   / 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .service-icon {
    display: inline-block;
  }

  .contact-form-grid {
    width: 80%;
    grid-row-gap: 50px;
    text-align: center;
    grid-template: "."
                   "."
                   / 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .get-in-touch-form {
    text-align: left;
  }

  .our-contacts {
    width: 80%;
    grid-template: "."
                   "."
                   "."
                   / 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .team-members {
    grid-template: ". ."
                   ". ."
                   ". ."
                   / 1fr 1fr;
  }

  .team-member-title-wrap {
    margin-bottom: 30px;
  }

  .project-details-grid {
    width: 80%;
    grid-row-gap: 50px;
    text-align: center;
    grid-template: "."
                   "."
                   / 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-detail-header-wrap {
    width: 70%;
  }

  .detail-header-image {
    height: 460px;
  }

  .blog-item {
    width: 80%;
  }

  .blog-preview-image {
    height: 460px;
  }

  .blog-summary-wrap {
    width: 100%;
  }

  .about-grid {
    grid-row-gap: 50px;
    text-align: center;
    grid-template: "."
                   "."
                   / 1fr;
  }

  .about-grid.cc-about-2 {
    grid-template-columns: 1fr;
    grid-template-areas: "."
                         ".";
  }

  .intro-text {
    width: auto;
  }

  .collection-wrap {
    flex-flow: column;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .blog-preview-wrap {
    width: 80%;
    text-align: center;
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    margin-left: 15px;
    margin-right: 15px;
  }

  .section.cc-cta {
    padding: 15px;
  }

  .section.cc-home-wrap, .section.cc-store-home-wrap {
    margin-left: 15px;
    margin-right: 15px;
  }

  .container {
    text-align: center;
  }

  .paragraph-bigger {
    font-size: 16px;
    line-height: 28px;
  }

  .rich-text {
    width: 90%;
    max-width: 470px;
    text-align: left;
  }

  .heading-jumbo {
    font-size: 50px;
    line-height: 64px;
  }

  .heading-jumbo-small {
    font-size: 30px;
    line-height: 52px;
  }

  .logo-link {
    padding-left: 0;
  }

  .navigation {
    padding: 20px 30px;
  }

  .cta-wrap {
    padding-left: 30px;
    padding-right: 30px;
  }

  .intro-content {
    width: 80%;
  }

  .our-services-grid {
    grid-row-gap: 60px;
    grid-template: "."
                   "."
                   "."
                   "."
                   "."
                   "."
                   / 1fr;
  }

  .status-message.cc-no-data {
    width: 80%;
    max-width: 470px;
  }

  .contact-form-wrap {
    padding: 30px;
  }

  .text-field.cc-contact-field, .text-field.cc-textarea {
    text-align: left;
  }

  .team-pic {
    height: 300px;
    margin-bottom: 30px;
  }

  .team-member-name {
    font-size: 16px;
    line-height: 28px;
  }

  .project-name-wrap {
    margin-bottom: 30px;
  }

  .project-name-link {
    font-size: 16px;
    line-height: 28px;
  }

  .detail-image {
    margin-bottom: 15px;
  }

  .blog-detail-header-wrap {
    width: 90%;
    max-width: 470px;
    text-align: left;
  }

  .blog-item {
    width: 90%;
    max-width: 470px;
  }

  .blog-summary-wrap {
    text-align: center;
  }

  .utility-page-wrap {
    padding: 15px;
  }

  ._404-wrap {
    padding: 30px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 36px;
    line-height: 52px;
  }

  .rich-text {
    width: 100%;
    max-width: none;
  }

  .heading-jumbo {
    font-size: 36px;
    line-height: 48px;
  }

  .menu {
    margin-left: 15px;
  }

  .navigation {
    padding-left: 20px;
    padding-right: 20px;
  }

  .menu-button, .menu-button.w--open {
    flex: none;
  }

  .cta-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }

  .status-message.cc-no-data, .contact-form-grid {
    width: 100%;
  }

  .our-contacts {
    width: 90%;
  }

  .team-members {
    grid-template: "."
                   "."
                   "."
                   "."
                   "."
                   "."
                   / 1fr;
  }

  .project-details-grid {
    width: 100%;
  }

  .blog-detail-header-wrap, .blog-item {
    width: 100%;
    max-width: none;
  }
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118e6-ec464778 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118ec-ec464778 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118f2-ec464778 {
  grid-area: 1 / 3 / 2 / 4;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118f8-ec464778 {
  grid-area: 2 / 3 / 3 / 4;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118fe-ec464778 {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b11905-ec464778 {
  grid-area: 2 / 2 / 3 / 3;
}

#w-node-_3f61509a-fac7-b0d5-3763-509be705858e-ec46477a {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-_3f61509a-fac7-b0d5-3763-509be70585a6-ec46477a {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb08-ec46477a {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb15-ec46477a {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb22-ec46477a {
  grid-area: 1 / 3 / 2 / 4;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387918-ec46477c {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387928-ec46477c {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387918-ec46477d {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387928-ec46477d {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387918-ec46477e {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387928-ec46477e {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38cdc-ec46477f {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38ce3-ec46477f {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38cea-ec46477f {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce9803c-ec464781 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce98043-ec464781 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce9804a-ec464781 {
  grid-area: 1 / 3 / 2 / 4;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce98051-ec464781 {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce98058-ec464781 {
  grid-area: 2 / 2 / 3 / 3;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce9805f-ec464781 {
  grid-area: 2 / 3 / 3 / 4;
}

@media screen and (max-width: 991px) {
  #w-node-ca1212d8-4247-8b87-bc90-543393b118f2-ec464778 {
    grid-area: 2 / 1 / 3 / 2;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118f8-ec464778 {
    grid-area: 3 / 2 / 4 / 3;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118fe-ec464778 {
    grid-area: 2 / 2 / 3 / 3;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b11905-ec464778 {
    grid-area: 3 / 1 / 4 / 2;
  }

  #w-node-_3f61509a-fac7-b0d5-3763-509be70585a6-ec46477a, #w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb15-ec46477a {
    grid-area: 2 / 1 / 3 / 2;
  }

  #w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb22-ec46477a {
    grid-area: 3 / 1 / 4 / 2;
  }

  #w-node-_421df62a-c217-12af-eee8-27f88a387928-ec46477c, #w-node-_421df62a-c217-12af-eee8-27f88a387928-ec46477d, #w-node-_421df62a-c217-12af-eee8-27f88a387928-ec46477e, #w-node-c28cc763-f708-1e61-f196-6bd9fce9804a-ec464781 {
    grid-area: 2 / 1 / 3 / 2;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce98051-ec464781 {
    grid-area: 2 / 2 / 3 / 3;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce98058-ec464781 {
    grid-area: 3 / 1 / 4 / 2;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce9805f-ec464781 {
    grid-area: 3 / 2 / 4 / 3;
  }
}

@media screen and (max-width: 767px) {
  #w-node-ca1212d8-4247-8b87-bc90-543393b118ec-ec464778 {
    grid-area: 2 / 1 / 3 / 2;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118f2-ec464778 {
    grid-area: 3 / 1 / 4 / 2;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118f8-ec464778 {
    grid-area: 6 / 1 / 7 / 2;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118fe-ec464778 {
    grid-area: 4 / 1 / 5 / 2;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b11905-ec464778 {
    grid-area: 5 / 1 / 6 / 2;
  }
}

@media screen and (max-width: 479px) {
  #w-node-c28cc763-f708-1e61-f196-6bd9fce98043-ec464781 {
    grid-area: 2 / 1 / 3 / 2;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce9804a-ec464781 {
    grid-area: 3 / 1 / 4 / 2;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce98051-ec464781 {
    grid-area: 4 / 1 / 5 / 2;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce98058-ec464781 {
    grid-area: 5 / 1 / 6 / 2;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce9805f-ec464781 {
    grid-area: 6 / 1 / 7 / 2;
  }
}


@font-face {
  font-family: 'Franklin Gothic Ssi';
  src: url('../fonts/Franklin-Gothic-Light-SSi-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Franklinatfbq';
  src: url('../fonts/FranklinATFBQ-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}